@tailwind base;
@tailwind components;
@tailwind utilities;

.main-content {
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}
